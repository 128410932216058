.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(79deg, #faf7f7, #d3ab0f 48%, #d3ab0f, #fdf8f8);
}
.auth-form-container,
.unsubscribe-form,
.register-form {
  display: flex;
  flex-direction: column;
}
label {
  text-align: left;
  padding: 0.25rem 0;
}
input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}
button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #09090a;
}
.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}
